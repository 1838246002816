<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="createDump(0)"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.dump.label.name') }}</b-th>
                            <b-th>{{ $t('settings.dump.label.date') }}</b-th>
                            <b-th>{{ $t('settings.dump.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="dump in dumps" :key="dump.name">
                            <b-td>
                                <a target="_blank" @click.prevent="downloadDump(dump.name)" href="#">{{ dump.name }}</a>
                            </b-td>
                            <b-td>{{ dump.date }}</b-td>
                            <b-td>
                                <b-button @click="deleteDump(dump.name)"
                                          size="sm"
                                          variant="danger"
                                          :title="$t('common.title.delete')"
                                >
                                    <font-awesome-icon icon="trash"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsDumps',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            dumps: [],
        }
    },
    methods: {
        ...mapGetters('Dumps', ['getDumps']),
        shown() {
            const dumpsFetch = this.$store.dispatch('Dumps/fetchDumps')


            Promise.all([dumpsFetch])
                .then(() => {
                    this.dumps = this.getDumps()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        createDump(id) {
            this.$createConfirm('Dumps/createDump', id, this.shown)
        },
        deleteDump(name) {
            this.$removeConfirm('Dumps/deleteDump', name, this.shown)
        },
        downloadDump(filename) {
            this.$root.downloadDocument(filename, 'dump')
        }
    },
    mounted() {
        this.shown()
    }
}
</script>